<template>
  <v-app>
    <Loader @done="loadingDone = true" v-if="!loadingDone"/>
    <div class="dashboard" v-else>
      <ItemTable/>

    </div>
  </v-app>
</template>

<script>
import Loader from './components/Loader.vue';
import ItemTable from './components/ItemTable.vue';

export default {
  name: 'App',
  mounted() {
    this.$vuetify.theme.dark = true;
  },
  components: {
    Loader,
    ItemTable
  },
  data() {
    return {
      loadingDone: false
    };
  }
}
</script>

<style lang="scss">
body {
  .v-application {
    font-family: 'Rajdhani', sans-serif;
    font-weight: 500;      
  }  
}
</style>