<template>
  <div class="loader">
    <v-progress-linear :value="progressBarWidth"></v-progress-linear>
    <div class="text">
        Loading static data..
        <span v-if="progress">{{ `${progress.now}/${progress.end}` }}</span>
    </div>    
  </div>
</template>

<script>
export default {
  mounted() {
    this.ItemManager.LoadItems((progress) => {
      this.progress = progress;
      if (this.progress.done) {
        this.$emit('done');
      }
    });
  },
  data() {
    return {
      progress: null
    };
  },
  computed: {
    progressBarWidth() {
      if (!this.progress) {
        return 0;
      }

      return this.progress.now / this.progress.end * 100;
    }
  }
}
</script>

<style lang="scss">
$dark: #222;
$text: #ccc;
$teal: rgb(35, 171, 165);

.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .v-progress-linear {
    width: 400px;
  }

  .text {
    text-align: center;
    text-transform: uppercase;
    margin-top: 10px;
  }  

  // .content {
  //   .progress-bar {
  //     height: 10px;
  //     width: 400px;
  //     background: #333;
  //     position: relative;

  //     .bar {
  //       position: absolute;
  //       left: 0;
  //       top: 0;
  //       height: 100%;
  //       width: 0;
  //       background: $teal;
  //     }
  //   }


  // }
}
</style>