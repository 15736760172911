<template>
  <div class="item-table">
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="itemsPerPage"
        :search="search"
        class="elevation-1"
        multi-sort
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="item in items"
              :key="item.name"
              @click="ShowHistory(item)"
            >
              <td class="name-column">
                <img :src="ItemManager.GetIconUrl(item)" /> {{ item.Name }}
              </td>
              <td>{{ item.Universalis.averagePriceNQ.toFixed(0) | gil }}</td>
              <td>{{ item.Universalis.averagePriceHQ.toFixed(0) | gil }}</td>
              <td>{{ item.Universalis.nqSaleVelocity.toFixed(3) }}</td>
              <td>{{ item.Universalis.hqSaleVelocity.toFixed(3) }}</td>
              <td>{{ item.Universalis.minPriceNQ.toFixed(0) | gil }}</td>
              <td>{{ item.Universalis.minPriceHQ.toFixed(0) | gil }}</td>
              <td>{{ item.Universalis.maxPriceNQ.toFixed(0) | gil }}</td>
              <td>{{ item.Universalis.maxPriceHQ.toFixed(0) | gil }}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      v-if="detailItem"
      :width="600"
    >
      <v-list-item>
        <v-list-item-avatar>
          <v-img :src="ItemManager.GetIconUrl(detailItem)"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ detailItem.Name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>HQ</th>
              <th>Price</th>
              <th>Qty.</th>
              <th>Total</th>
              <th>Buyer</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(entry, index) in detailItem.Universalis.recentHistory"
              :key="index"
            >
              <td class="hq-icon">
                <img src="@/assets/hq-icon.png" v-if="entry.hq" />
              </td>
              <td>{{ entry.pricePerUnit.toFixed(0) | gil }}</td>
              <td>{{ entry.quantity }}</td>
              <td>
                {{ (entry.pricePerUnit * entry.quantity).toFixed(0) | gil }}
              </td>
              <td>{{ entry.buyerName }}</td>
              <td>{{ entry.timestamp | datetime }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemsPerPage: 15,
      search: "",
      headers: [
        {
          text: "Name",
          value: "Name",
        },
        {
          text: "Avg. Price NQ",
          value: "Universalis.averagePriceNQ",
        },
        {
          text: "Avg. Price HQ",
          value: "Universalis.averagePriceHQ",
        },
        {
          text: "NQ Velocity",
          value: "Universalis.nqSaleVelocity",
        },
        {
          text: "HQ Velocity",
          value: "Universalis.hqSaleVelocity",
        },
        {
          text: "Min. Price NQ",
          value: "Universalis.minPriceNQ",
        },
        {
          text: "Min. Price HQ",
          value: "Universalis.minPriceHQ",
        },
        {
          text: "Max. Price NQ",
          value: "Universalis.maxPriceNQ",
        },
        {
          text: "Max. Price HQ",
          value: "Universalis.maxPriceHQ",
        },
      ],
      items: this.ItemManager.itemDB,
      drawer: null,
      detailItem: null,
    };
  },
  methods: {
    ShowHistory(item) {
      this.detailItem = item;
      console.log(item);
      this.drawer = true;
    },
  },
};
</script>

<style lang="scss">
.item-table {
  margin: 20px;

  .name-column {
    display: flex;
    align-items: center;

    img {
      width: 30px;
      margin-right: 10px;
    }
  }

  thead {
    // background: #111;

    th {
      font-weight: 500;
      padding: 5px 10px;
    }
  }

  tbody {
    tr {
      cursor: pointer;
    }
  }

  .hq-icon {
    img {
      position: relative;
      top: 2px;
    }
  }
}
</style>
