import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
import localforage from 'localforage';
import memoryDriver from 'localforage-memoryStorageDriver';

export default class ItemManager {
    constructor() {
        this.apiUrl = "https://xivapi.com/";
        this.universalisUrl = "https://universalis.app/api/";
        this.server = "Lich";
        this.itemDB = [];
        this.LIMIT = 250;
    }

    LoadItems(cbProgress) {    

        localforage.defineDriver(memoryDriver);
        const forageStore = localforage.createInstance({
            driver: [
                localforage.INDEXEDDB,
                localforage.LOCALSTORAGE,
                memoryDriver._driver
            ],
            name: 'universalis-cache'
        });

        const cache = setupCache({
            maxAge: 60 * 60 * 1000, //1 hour
            store: forageStore
        });

        const api = axios.create({
            adapter: cache.adapter
        });

        let resultHandler = (result) => {
            let pagination = result.data.Pagination;
            let done = pagination.PageNext == 1;

            let ids = [];

            for (let entry of result.data.Results) {
                this.itemDB.push(entry);
                ids.push(entry.ID);
            }

            api.get(this.universalisUrl + this.server + "/" + ids.join(",")).then(async (secondResult) => {
                for (let entryA of secondResult.data.items) {
                    for (let entryB of this.itemDB) {
                        if (entryA.itemID == entryB.ID) {
                            entryB["Universalis"] = entryA;
                        }
                    }
                }

                //const length = await cache.store.length();

                cbProgress({
                    now: pagination.Page,
                    end: pagination.PageTotal,
                    done: done               
                });
    
                if (!done) {
                    this.ItemRequest({
                        limit: this.LIMIT,
                        page: pagination.PageNext
                    }).then(resultHandler);
                } else {
                    this.itemDB = this.FilterData();
                    console.log(this.itemDB);
                }
            });
        };

        this.ItemRequest({limit: this.LIMIT}).then(resultHandler);
    }

    ItemRequest(params = {}) {
        return axios.get(this.apiUrl + "item", {params: params});
    }

    GetIconUrl(item) {
        return this.apiUrl + item.Icon.substr(1);
    }

    FilterData() {
        let data = [];
        for (let entry of this.itemDB) {
            if (entry.Name == '') {
                continue;
            }

            if (entry.Universalis == undefined || entry.Universalis.recentHistory.length == 0) {
                continue;
            }

            data.push(entry);
        }

        return data;
    }
}