import Vue from 'vue'

import App from './App.vue'
import ItemManager from './item-manager'

import { VuejsDatatableFactory } from 'vuejs-datatable';
import vuetify from './plugins/vuetify';
Vue.use( VuejsDatatableFactory );

Vue.config.productionTip = false

Vue.prototype.ItemManager = new ItemManager();

Vue.filter('gil', function(val) {
  if (!val) {
    return '';
  }

  let str = new Intl.NumberFormat('de-DE', {
    style: 'currency', 
    currency: 'EUR'
  }).format(val);

  return str.substr(0, str.length - 5);
});

Vue.filter('datetime', function(val) {
  if (!val) {
    return '';
  }

  let d = new Date(val * 1000);
  return d.toLocaleString([], { timeStyle: 'short', dateStyle: 'short' });
})

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
